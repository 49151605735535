import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import FeatureFunctions from '../../components/AboutUs/FeatureFunctions'
import featureImg from '../../assets/images/features/BlueMail_Features_Security_Privacy_v2.webp'
import {graphql} from 'gatsby'
const img = '/img/features/BlueMail_Features_Security_Privacy_v2.png'

const dataSecurity = {
  title: "Unrivaled Security with BlueMail",
  subtitle: <p>At BlueMail, we don't just prioritize your security and privacy - we redefine it. Our dedicated team is committed to the highest standards in security protocols, encryption, and authorization measures, ensuring your email accounts are fortified against any breach.
  <br />
  <br /> 
  <h5>Advanced Encryption with SSL/TLS and STARTTLS</h5>
  Experience unparalleled security with BlueMail's adoption of cutting-edge encryption technologies, <a href="/security/ssl-tls-starttls/" target="_blank" rel="noreferrer">SSL/TLS and STARTTLS</a>. These robust protocols are our assurance to you that every piece of your email communication is encrypted, keeping your sensitive personal and professional data under lock and key from prying eyes.
  <br />
  <br />
  <h5>OAuth2: The Gold Standard of Secure Connections</h5>
  BlueMail leverages the highly secure <a href="https://bluemail.me/help/what-is-oauth/" target="_blank" rel="noreferrer">OAuth2</a> authorization protocol, especially with providers like Gmail, Google Suite, Office365, and Yahoo. This means accessing your account through a secure token, bypassing the need for password entry, and enhancing your security manifold.
  <br />
  <br />
  <h5>Empowering Email Security with S/MIME</h5>
  Elevate your email security with BlueMail's <a href="https://bluemail.me/security/smime/" target="_blank" rel="noreferrer">S/MIME (Secure/Multipurpose Internet Mail Extensions)</a> feature. It not only encrypts your emails end-to-end but also authenticates the sender through digital signatures, ensuring both confidentiality and integrity of your communications.
  <br />
  <br />
  <h5>Pixel Tracking Detection</h5>
  Stay one step ahead of email trackers with BlueMail’s <a href="/blog/pixel-blocking/" target="_blank" rel="noreferrer">Pixel Tracking Detection</a> feature. We empower you to maintain your privacy by alerting you whenever an email attempts to track your activity through hidden pixels. This proactive approach ensures you have control over your digital footprint, enhancing your email privacy and security.
  <br />
  <br />
  <h5>Lock screen</h5>
  BlueMail's <a href="/security/lock-screen/" target="_blank" rel="noreferrer">Lock Screen feature</a> is your personal security guard. Whether you choose a passcode or fingerprint lock, your emails are secured against unauthorized access, giving you peace of mind, even in the event of device loss or theft.
  <br />
  <br />
  <h5>Reliable Backup and Disaster Recovery</h5>
  With BlueMail’s <a href="https://bluemail.me/help/data-backup-desaster-recovery/" target="_blank" rel="noreferrer">Secure Account Backup and Recovery</a> tool, transitioning to a new device or recovering from a lost phone is seamless. Your account configurations are backed up securely and restored effortlessly, thanks to our robust two-step authentication process.
  <br />
  <br />
  Discover more about how BlueMail leads the way in email security at our <a href="https://bluemail.me/security/" target="_blank" rel="noreferrer">BlueMail Security Center</a>.
  </p>,
  img: featureImg,
  class: ''
}


const Security = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='BlueMail Security | BlueMail App' 
                postDescription='Your privacy and security are extremely important to us. Ensuring that your email remains secure and private is of the utmost priority to BlueMail.'
                postImage={img}
                postURL='features-functions/security'
                postSEO
            />
            <Navbar />
            <FeatureFunctions data={dataSecurity} />
            <Footer />
        </Layout>
    )
}

export default Security

export const query = graphql`
query SecurityPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
