import React from 'react'

const FeatureFunctions = ({data}) => {
    return (
        <div className={"features-categories-container bg-nav " + data.class}>
            <div className="container">
            <div className="row">
                <div className='col-12' style={{textAlign: 'center'}}>
                <img src={data.img} alt={'BlueMail Features ' + data.title} />
                </div>
            </div>
            <div className="row">
                <div className='col-12'>
                    <h1>{data.title}</h1>
                    <hr />
                </div>
                <div className='col-12'>
                    <p>
                        {data.subtitle}
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default FeatureFunctions